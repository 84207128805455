import { ComparativeDisplayType, ZeroDisplayType } from "@vp/vp-js-token-engine";

import { Locale } from "./locales";

export enum PriceType {
  TotalListPrice = "totalListPrice",
  TotalDiscountedPrice = "totalDiscountedPrice",
  UnitListPrice = "unitListPrice",
  UnitDiscountedPrice = "unitDiscountedPrice",
  LowestListPrice = "lowestListPrice",
  HighestListPrice = "highestListPrice",
  LowestDiscountPrice = "lowestDiscountPrice",
  HighestDiscountPrice = "highestDiscountPrice",
  PriceDifference = "priceDifference",
  PriceDifferenceDiscounted = "priceDifferenceDiscounted",
}

export enum PricePresentationType {
  QtyStartingAtPrice = "qtyStartingAtPrice",
  QtyFromPrice = "qtyFromPrice",
  AsLowAsPricePerPiece = "asLowAsPricePerPiece",
  PricePerUnit = "pricePerUnit",
  PlusOrMinus = "plusOrMinus",
  RawPrice = "rawPrice",
  RangePrice = "rangePrice",
  PriceWithQtyAndUnit = "priceWithQtyAndUnit",
  RawAndPricePerUnit = "rawAndPricePerUnit",
}

export interface PriceProperties {
  minimumFractionDigits: number;
  locale: Locale | string;
  tenant?: string;
  currency: string;
  vatInc: boolean;
  quantity?: number;
  hideVatMessage?: boolean;
  hideShippingMessageInVatMessage?: boolean;
  isPriceNegative?: boolean;
  zeroDisplayType?: ZeroDisplayType;
  comparativeDisplayType?: ComparativeDisplayType;
}

export interface Price {
  taxed: number;
  untaxed: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PricePresentationToPriceTypesMap = {
  qtyStartingAtPrice: [PriceType.TotalListPrice, PriceType.TotalDiscountedPrice],
  qtyFromPrice: [PriceType.TotalListPrice, PriceType.TotalDiscountedPrice],
  asLowAsPricePerPiece: [PriceType.UnitListPrice, PriceType.UnitDiscountedPrice],
  pricePerUnit: [PriceType.UnitListPrice, PriceType.UnitDiscountedPrice],
  plusOrMinus: [PriceType.PriceDifference, PriceType.PriceDifferenceDiscounted],
  rawPrice: [PriceType.TotalListPrice, PriceType.TotalDiscountedPrice],
  rangePrice: [
    PriceType.HighestListPrice,
    PriceType.HighestDiscountPrice,
    PriceType.LowestListPrice,
    PriceType.LowestDiscountPrice,
  ] as const,
  priceWithQtyAndUnit: [
    PriceType.UnitListPrice,
    PriceType.UnitDiscountedPrice,
    PriceType.TotalListPrice,
    PriceType.TotalDiscountedPrice,
  ] as const,
  rawAndPricePerUnit: [
    PriceType.TotalListPrice,
    PriceType.TotalDiscountedPrice,
    PriceType.UnitListPrice,
    PriceType.UnitDiscountedPrice,
  ] as const,
} as const;

export type PricesTypesBasedOnPricePresentation<T extends PricePresentationType> =
  (typeof PricePresentationToPriceTypesMap)[T][number];
