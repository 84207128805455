import { DiscountPrice } from "@vp/swan";
import { t } from "i18next";
import React, { useEffect, useState } from "react";

import { calculateTotalSavings } from "../../clients/websitePricingClient";
import { PriceDetails } from "../../types/tieredPrices";

const volumeDiscountToken = "{0}";

interface SavingsProps {
  priceDetails: PriceDetails;
}

// If savings exist, return the volume discount, or volume discount + sale message depending on the discount.
// If no savings are available, return null.

const Savings = ({ priceDetails }: SavingsProps) => {
  const [totalSavingsMessage, setTotalSavingsMessage] = useState("");
  const [totalSaving, setTotalSaving] = useState({
    savings: 0,
    isDiscounted: false,
  });

  useEffect(() => {
    const fetchTotalSavings = async () => {
      if (priceDetails) {
        const result = await calculateTotalSavings(priceDetails);
        setTotalSaving(result);
      }
    };

    fetchTotalSavings();
  }, [priceDetails]);

  useEffect(() => {
    if (totalSaving.savings > 0) {
      const message = totalSaving.isDiscounted
        ? t("price-platform.volume-discount-sale")
        : t("price-platform.volume-discount.value");
      setTotalSavingsMessage(message.replace(volumeDiscountToken, totalSaving.savings.toString()));
    }
  }, [totalSaving]);

  if (totalSaving.savings === 0) return null;

  return totalSavingsMessage ? <DiscountPrice>{totalSavingsMessage}</DiscountPrice> : null;
};

export default Savings;
