import { useEffect, useState } from "react";

// Custom hook to track if the component has mounted
const useMounted = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true); // Set to true when the component mounts
  }, []);

  return isMounted;
};

export default useMounted;
