import { Box, Typography } from "@vp/swan";
import { t } from "i18next";
import React from "react";

export type MoqProps = {
  moq: number;
};

// Implement a helper function, getMoqText, to generate the text to display based on the moq value:
//  - If moq is greater than 1, return a formatted string indicating the minimum number of units required.
//  - If moq is 1 or less, return an empty string, indicating no specific message is needed.

export const Moq = ({ moq }: MoqProps) => {
  const getMoqText = (): string => {
    if (moq > 1) {
      return `${t("price-platform.minimum-quantity-units").replace("{0}", moq.toString())}`;
    } else {
      return "";
    }
  };

  return (
    <Typography>
      <Box>{getMoqText()}</Box>
    </Typography>
  );
};
