import { FlexBox } from "@vp/swan";
import {
  AsLowAsPricePerPiece,
  PlusOrMinus,
  PriceForQtyUnits,
  PricePerUnit,
  QtyFromPrice,
  QtyStartingAtPrice,
  RangePrice,
  RawPrice,
  VatMessage,
} from "@vp/vp-tokenized-fragment";
import { t } from "i18next";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { defaultTenant } from "../../constants/global";
import { PageId } from "../../types/pageId";
import {
  Price,
  PricePresentationType,
  PriceProperties,
  PricesTypesBasedOnPricePresentation,
  PriceType,
} from "../../types/prices";
import {
  getAsLowAsPerPiecePrice,
  getPlusMinusPrice,
  getPrice,
  getPricePerUnit,
  getPriceWithQtyAndUnit,
  getQuantityFromPrice,
  getQuantityPrice,
  getRangedPrice,
  getRawAndUnitPrice,
} from "../../util/price-transform";
import { SetVersionOnWindow } from "../../util/version-logger";
import CSRWrapper from "../CSRWrapper";
import PriceErrorBoundary from "../PriceErrorBoundary";
import { SmallFontWrapper } from "../PricePlatform.styles";

// Accept pricePresentationType, prices and priceProperties and display price according to the pricePresentationType
interface BasePricePresentationProps {
  presentationType: PricePresentationType;
  prices: Partial<Record<PriceType, Price>>;
  priceProperties: PriceProperties;
  placeHolderText?: string;
  className?: string;
  pageId?: PageId;
}

// Type that matches the specific presentation type
interface PricePresentationPropDecider<T extends PricePresentationType>
  extends BasePricePresentationProps {
  presentationType: T;
  prices: T extends PricePresentationType.PlusOrMinus
    ? Record<PricesTypesBasedOnPricePresentation<T>, number>
    : Record<PricesTypesBasedOnPricePresentation<T>, Price>;
}

// Consolidated type for PricePresentationProps
type PricePresentationProps =
  | PricePresentationPropDecider<PricePresentationType.PricePerUnit>
  | (PricePresentationPropDecider<PricePresentationType.QtyStartingAtPrice> & {
      priceProperties: { quantity: number };
    })
  | PricePresentationPropDecider<PricePresentationType.RangePrice>
  | PricePresentationPropDecider<PricePresentationType.RawPrice>
  | PricePresentationPropDecider<PricePresentationType.RawAndPricePerUnit>
  | (PricePresentationPropDecider<PricePresentationType.QtyFromPrice> & {
      priceProperties: { quantity: number };
    })
  | (PricePresentationPropDecider<PricePresentationType.PriceWithQtyAndUnit> & {
      priceProperties: { quantity: number };
    })
  | PricePresentationPropDecider<PricePresentationType.AsLowAsPricePerPiece>
  | PricePresentationPropDecider<PricePresentationType.PlusOrMinus>;

// Component to render different price presentations
const PricePresentationCore = ({
  presentationType,
  priceProperties,
  placeHolderText = "",
  prices,
  className,
  pageId,
}: PricePresentationProps): JSX.Element | null => {
  const { i18n, ready } = useTranslation();
  const { locale, vatInc } = priceProperties;
  const tenant = priceProperties.tenant || defaultTenant;

  SetVersionOnWindow();

  useEffect(() => {
    if (ready && priceProperties.locale !== i18n.language) {
      i18n.changeLanguage(priceProperties.locale);
    }
  }, [i18n, priceProperties.locale, ready]);

  if (!ready) {
    return <></>;
  }

  const renderPriceComponent = () => {
    switch (presentationType) {
      case PricePresentationType.PricePerUnit: {
        const priceInfo = getPricePerUnit(prices, priceProperties);
        return <PricePerUnit placeHolderText={placeHolderText} pricingInfo={priceInfo} />;
      }

      // V2 UPDATES: Add quantity from wrapper here - or should we migrate both to quantity starting at price?
      case PricePresentationType.QtyStartingAtPrice: {
        const priceInfo = getQuantityPrice(prices, priceProperties);
        return (
          <QtyStartingAtPrice
            placeHolderText={placeHolderText}
            pricingInfo={priceInfo}
            classNameWrapper={className}
          />
        );
      }

      case PricePresentationType.QtyFromPrice: {
        const priceInfo = getQuantityFromPrice(prices, priceProperties);
        return (
          <QtyFromPrice
            placeHolderText={placeHolderText}
            pricingInfo={priceInfo}
            classNameWrapper={className}
          />
        );
      }

      // V2 UPDATES: Use this for Range Price
      // V2 UPDATES: Add Price per quantity and price per unit to this for Range price for quantity + price per unit
      case PricePresentationType.RangePrice: {
        const priceInfo = getRangedPrice(prices, priceProperties);
        const isDiscounted = prices.lowestDiscountPrice.taxed < prices.lowestListPrice.taxed;
        return (
          <FlexBox flexDirection={"column"}>
            <FlexBox flexDirection={"row"} alignItems={isDiscounted ? "flex-end" : "center"}>
              <RangePrice
                placeHolderText={placeHolderText}
                pricingInfo={{
                  ...priceInfo,
                  hideVatMessage: true,
                  hideShippingMessageInVatMessage: true,
                }}
                splitInMultipleLines
              />
              &nbsp;
              <SmallFontWrapper>
                {pageId === PageId.LatPDP ? `/ ${t("price-platform.unit")}` : ""}
              </SmallFontWrapper>
            </FlexBox>
            {priceProperties.locale && tenant && (
              <SmallFontWrapper>
                <VatMessage
                  pricingInfo={{
                    vatInc: vatInc,
                    culture: locale,
                  }}
                  tenant={tenant}
                  placeHolderText=""
                ></VatMessage>
              </SmallFontWrapper>
            )}
          </FlexBox>
        );
      }

      case PricePresentationType.PriceWithQtyAndUnit: {
        const priceInfo = getPriceWithQtyAndUnit(prices, priceProperties);
        return (
          <FlexBox flexDirection={"column"}>
            <PriceForQtyUnits
              placeHolderText={placeHolderText}
              pricingInfo={{
                ...priceInfo,
                hideVatMessage: true,
                hideShippingMessageInVatMessage: true,
              }}
            />
            {priceProperties.quantity > 1 && prices.unitDiscountedPrice && prices.unitListPrice ? (
              <SmallFontWrapper>
                <PricePerUnit placeHolderText={placeHolderText} pricingInfo={priceInfo} />
              </SmallFontWrapper>
            ) : (
              locale &&
              tenant && (
                <SmallFontWrapper>
                  <VatMessage
                    pricingInfo={{
                      vatInc: vatInc,
                      culture: locale,
                    }}
                    tenant={tenant}
                    placeHolderText=""
                  ></VatMessage>
                </SmallFontWrapper>
              )
            )}
          </FlexBox>
        );
      }

      // V2 UPDATES: Use this for Raw Price
      // V2 UPDATES: Update or copy this for RawAndPricePerUnit Wrapper (same thing with unit price on next line)
      case PricePresentationType.RawPrice: {
        const priceInfo = getPrice(prices, priceProperties);
        return (
          <RawPrice
            placeHolderText={placeHolderText}
            pricingInfo={priceInfo}
            classNameWrapper={className}
          />
        );
      }

      case PricePresentationType.RawAndPricePerUnit: {
        const priceInfo = getRawAndUnitPrice(prices, priceProperties);
        return (
          <FlexBox flexDirection={"column"}>
            <RawPrice
              placeHolderText={placeHolderText}
              pricingInfo={priceInfo}
              classNameWrapper={className}
            />
            <PricePerUnit placeHolderText={placeHolderText} pricingInfo={priceInfo} />
          </FlexBox>
        );
      }

      case PricePresentationType.AsLowAsPricePerPiece: {
        const priceInfo = getAsLowAsPerPiecePrice(prices, priceProperties);
        return (
          <AsLowAsPricePerPiece
            placeHolderText={placeHolderText}
            pricingInfo={priceInfo}
            classNameWrapper={className}
          />
        );
      }

      case PricePresentationType.PlusOrMinus: {
        const priceInfo = getPlusMinusPrice(prices, priceProperties);
        return (
          <PlusOrMinus
            placeHolderText={placeHolderText}
            pricingInfo={priceInfo}
            classNameWrapper={className}
          />
        );
      }
    }
  };

  return prices ? renderPriceComponent() : <></>;
};

const PricePresentation = (pricePresentationProps: PricePresentationProps) => {
  return (
    <CSRWrapper serverComponent={<></>}>
      <PriceErrorBoundary fallbackUi={<></>}>
        <PricePresentationCore {...pricePresentationProps} />
      </PriceErrorBoundary>
    </CSRWrapper>
  );
};

export { PricePresentation };
export type { PricePresentationProps };
export default PricePresentation;
