import { SkeletonText, SkeletonTextLine } from "@vp/swan";
import React, { useEffect } from "react";
import { I18nextProvider } from "react-i18next";

import usePricingContextInitialization from "../../hooks/usePricingContextInitialization";
import i18n from "../../localization/i18next";
import { useProductStore } from "../../store/productPriceDetails.store";
import { PriceProperties } from "../../types/prices";
import { getPriceQueryKey } from "../../util/price-extraction";
import { PriceDisplayProps } from "../PriceDisplay";
import PricePresentation from "../PricePresentation";

// 1. Accept details like mpvId, selections (optionally), quantity (optionally) or quantityEnum (optionally),
// 2. Fetch price details from store
// 3. Pass the price details to this component

export type LowestPriceDisplayProps = PriceDisplayProps;

const LowestPriceDisplay = ({
  mpvId,
  productKey,
  version,
  quantity,
  cacheableQuantities,
  selections,
  requestedDisplayStrategy,
}: LowestPriceDisplayProps) => {
  const addPriceQuery = useProductStore((state) => state.addPriceQuery);
  const basePriceExtractionConfig = useProductStore((state) => state.basePriceExtractionConfig);

  // Generating a unique key for the current price query using provided parameters
  const priceQueryKey =
    getPriceQueryKey({
      mpvId,
      selections,
      quantity,
      cacheableQuantities,
      productKey,
      version,
    }) || "";

  // Retrieving the product price details from the store based on the generated price query key
  const productPriceDetails = useProductStore(
    (state) => state.productPriceDetails?.[priceQueryKey],
  );

  const { locale = "", tenant = "", developmentMode = false } = basePriceExtractionConfig || {};
  const vatIncl = useProductStore((state) => state.vatIncl) || false;

  const pricingContextInitialized = usePricingContextInitialization(
    tenant,
    locale,
    developmentMode,
  );

  // Adding a price query to the store when any of the dependencies change
  useEffect(() => {
    if (pricingContextInitialized) {
      addPriceQuery({
        mpvId,
        productKey,
        version,
        selections,
        quantity,
        cacheableQuantities,
        requestedDisplayStrategy,
      });
    }
  }, [
    addPriceQuery,
    mpvId,
    quantity,
    cacheableQuantities,
    selections,
    requestedDisplayStrategy,
    productKey,
    version,
    vatIncl,
    pricingContextInitialized,
  ]);

  if (!pricingContextInitialized || !productPriceDetails || !basePriceExtractionConfig) {
    return (
      <SkeletonText>
        <SkeletonTextLine width="narrow"></SkeletonTextLine>
      </SkeletonText>
    ); //  loading spinner/UI
  }

  // return empty fragment when the API returns null or undefined for productPriceDetails.prices
  if (!productPriceDetails.prices || !productPriceDetails.prices.estimatedPrices) {
    return <></>;
  }

  // the following quantity is evaluated based on the requested quantity from the user or is extracted from the pricng response for default quantity
  const evaluatedQuantity: number =
    quantity || Number(Object.keys(productPriceDetails.prices.estimatedPrices)[0]);

  const priceProperties: PriceProperties & { quantity: number } = {
    minimumFractionDigits: productPriceDetails?.prices?.fractionDigits,
    locale: locale,
    currency: productPriceDetails?.prices?.currency,
    vatInc: Boolean(vatIncl),
    quantity: evaluatedQuantity,
  };

  return (
    <>
      {productPriceDetails?.displayStrategy && (
        <I18nextProvider i18n={i18n}>
          <PricePresentation
            presentationType={productPriceDetails.displayStrategy}
            prices={productPriceDetails?.prices?.estimatedPrices[evaluatedQuantity]}
            priceProperties={priceProperties}
          />
        </I18nextProvider>
      )}
    </>
  );
};

export { LowestPriceDisplay };
