import { Resource } from "i18next";

import { Locale } from "../types/localization";
import daDK from "./texts/da-dk.json";
import deDE from "./texts/de-de.json";
import enGB from "./texts/en-gb.json";
import enIE from "./texts/en-ie.json";
import enUS from "./texts/en-us.json";
import esES from "./texts/es-es.json";
import esUS from "./texts/es-us.json";
import fiFI from "./texts/fi-fi.json";
import frCA from "./texts/fr-ca.json";
import frFR from "./texts/fr-fr.json";
import itIT from "./texts/it-it.json";
import nbNO from "./texts/nb-no.json";
import nlNL from "./texts/nl-nl.json";
import ptPT from "./texts/pt-pt.json";
import svSE from "./texts/sv-se.json";

export const resources: Resource = {
  [Locale.deDE]: deDE,
  [Locale.esES]: esES,
  [Locale.enUS]: enUS,
  [Locale.enGB]: enGB,
  [Locale.enIE]: enIE,
  [Locale.fiFI]: fiFI,
  [Locale.frFR]: frFR,
  [Locale.nlNL]: nlNL,
  [Locale.itIT]: itIT,
  [Locale.svSE]: svSE,
  [Locale.nbNO]: nbNO,
  [Locale.ptPT]: ptPT,
  [Locale.daDK]: daDK,
  [Locale.frCA]: frCA,
  [Locale.esUS]: esUS,
};
