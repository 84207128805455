const getPricingContextVatInclusivity = () => {
  const doc = getDocument();
  return doc?.pcxtV3?.isVatInclusive() ?? "";
};

const getPricingContext = () => {
  const doc = getDocument();
  return doc?.pcxtV3?.getEncodedContextString() ?? "";
};

const isPricingContextInitialized = (developmentMode?: boolean): boolean => {
  const doc = getDocument();
  return developmentMode ? true : doc?.pcxtV3?.isInitialized() ?? false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getDocument = (): any => {
  return typeof document !== "undefined" ? document : undefined;
};

export { getPricingContext, getPricingContextVatInclusivity, isPricingContextInitialized };
