import {
  getEstimatedPriceAsync,
  getMaxTierQuantityAsync,
} from "@cimpress-technology/website-pricing-client";

import { PriceDetails, PricePerQuantity, RawPricePerQuantity } from "../types/tieredPrices";
import {
  buildPricePerQuantity,
  getPercentage,
  getUnitPrice,
  isDiscounted,
  validatePricePerQuantity,
} from "../util/price-calculator-helper";

async function getPricePerQuantity(
  quantity: number,
  productKey: string,
  productVersion: number,
  merchantId: string,
  market: string,
  includeSetUpFee: boolean,
  requestor: string,
  selections?: Record<string, string>,
  pricingContext?: string,
  fallbackPrice?: PricePerQuantity,
  hidePerUnitPrice = false,
): Promise<PricePerQuantity> {
  try {
    const totalPrice: RawPricePerQuantity = await getEstimatedPriceAsync(
      requestor,
      merchantId,
      market,
      productKey,
      productVersion,
      quantity,
      selections,
      undefined,
      null,
      pricingContext,
    );
    return buildPricePerQuantity(totalPrice, quantity, includeSetUpFee, hidePerUnitPrice);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (fallbackPrice) {
      return fallbackPrice;
    }
    throw new Error(`Unable to retrieve the price per quantity: ${error.message}`);
  }
}

async function getMaxTierQuantity(
  productKey: string,
  productVersion: number,
  merchantId: string,
  market: string,
  requestor: string,
  selections?: Record<string, string>,
  pricingContext?: string,
): Promise<number | undefined> {
  try {
    return await getMaxTierQuantityAsync(
      requestor,
      merchantId,
      market,
      productKey,
      productVersion,
      selections,
      null,
      pricingContext,
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    console.error(`Unable to get the max tier quantity: ${err.message}.`);
    return undefined;
  }
}

async function calculateTotalSavings(pricingDetails: PriceDetails) {
  validatePricePerQuantity(pricingDetails.priceForQuantity);
  const unitPriceMoq = pricingDetails.isVatInclusive
    ? pricingDetails.UnitListPriceForMoq.taxed
    : pricingDetails.UnitListPriceForMoq.untaxed;
  const unitPrice = getUnitPrice(pricingDetails.priceForQuantity, pricingDetails.isVatInclusive);
  const savings = getPercentage(unitPrice.unitDiscountedPrice, unitPriceMoq);
  return {
    savings,
    isDiscounted: isDiscounted(pricingDetails.priceForQuantity, pricingDetails.isVatInclusive),
  };
}

export { calculateTotalSavings, getMaxTierQuantity, getPricePerQuantity };
