import packageJson from "../../package.json";
import useMounted from "../hooks/useMounted";

declare global {
  interface Window {
    PRICE_PLATFORM_VERSION: string;
  }
}

export const SetVersionOnWindow = () => {
  const isMounted = useMounted();
  if (isMounted) {
    const pricePlatformVersion = packageJson.version;
    window.PRICE_PLATFORM_VERSION = pricePlatformVersion;
  }
};
