import { PriceQuery } from "../store/productPriceDetails.types";

type PriceQuerySubset = Pick<
  PriceQuery,
  "mpvId" | "productKey" | "version" | "quantity" | "cacheableQuantities" | "selections"
>;

type SerializableValue =
  | string
  | number
  | Record<string, string>
  | Array<number>;

export const combineQuantities = (
  quantities: number[] | undefined,
  selectedQuantity: number | undefined,
) => {
  return selectedQuantity
    ? Array.from(new Set([...(quantities ?? []), selectedQuantity]))
    : quantities ?? [];
};

export const getPriceQueryKey = (priceQuery: PriceQuerySubset): string => {
  if (typeof priceQuery !== "object" || priceQuery === null) {
    throw new Error("Input must be a non-null object");
  }

  // Preprocess priceQuery by removing cacheableQuantities and quantity,  
  // then adding a new quantities field with the combined values of cacheableQuantities and quantity.
  const { cacheableQuantities, quantity, ...queryWithoutQuantities } = priceQuery;

  const combinedQuantities = combineQuantities(cacheableQuantities, quantity);
  const processedQuery = {
    ...queryWithoutQuantities,
    ...(combinedQuantities.length > 0 ? { quantities: combinedQuantities } : {}),
  };

  const serialize = (
    value: SerializableValue,
  ): string => {
    if (typeof value === "string" || typeof value === "number" || typeof value === "boolean") {
      return String(value);
    } else if (Array.isArray(value)) {
      return value.map((item) => String(item)).join("-");
    } else if (typeof value === "object" && value !== null) {
      return Object.keys(value)
        .sort()
        .map(key => `${key}:${serialize(value[key])}`)
        .join("::");
    } else {
      return String(value);
    }
  };

  const parts: string[] = [];
  const queryKeys = Object.keys(processedQuery).sort();

  queryKeys.forEach((key) => {
    const value = processedQuery[key as keyof typeof processedQuery];
    if (
      value === undefined ||
      (typeof value === "object" && value !== null && Object.keys(value).length === 0)
    ) {
      return;
    }
    parts.push(`${key}:${serialize(value)}`);
  });

  return parts.sort().join("::");
};
