import { Box } from "@vp/swan";
import React, { ReactNode, useEffect, useState } from "react";

import useMounted from "../../hooks/useMounted";

type CSRWrapperProps = {
  serverComponent: ReactNode;
  children: ReactNode;
};

const CSRWrapper = ({ serverComponent, children }: CSRWrapperProps) => {
  const isMounted = useMounted();
  const [uniqueID, setUniqueID] = useState<number | null>(null);

  useEffect(() => {
    setUniqueID(Math.random());
  }, [isMounted]);

  if (!isMounted) return <>{serverComponent}</>; // SSR fallback

  return <Box key={`price-platform-${uniqueID}`}>{children}</Box>;
};

export default CSRWrapper;
