import { useEffect } from "react";

import { useProductStore } from "../../store/productPriceDetails.store";
import { BasePriceExtractionConfig } from "../../store/productPriceDetails.types";

export const useSetPriceConfigs = (baseConfig: BasePriceExtractionConfig) => {
  const setBasePriceExtractionConfig = useProductStore(
    (state) => state.setBasePriceExtractionConfig,
  );
  useEffect(() => {
    // setting the baseconfig as it is
    setBasePriceExtractionConfig(baseConfig);
  }, [baseConfig, setBasePriceExtractionConfig]);
};
