import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { Locale } from "../../types/localization";
import { resources } from "../index";

i18n.use(initReactI18next).init({
  resources: resources,
  fallbackLng: {
    [Locale.enIE]: [Locale.enGB, Locale.enUS],
    [Locale.enGB]: [Locale.enUS],
    [Locale.frBE]: [Locale.frFR, Locale.enUS],
    [Locale.frCA]: [Locale.frFR, Locale.enUS],
    [Locale.nlBE]: [Locale.nlNL, Locale.enUS],
    [Locale.deAT]: [Locale.deDE, Locale.enUS],
    [Locale.deCH]: [Locale.deDE, Locale.enUS],
    [Locale.itCH]: [Locale.itIT, Locale.enUS],
    [Locale.frCH]: [Locale.frFR, Locale.enUS],
    [Locale.enAU]: [Locale.enGB, Locale.enUS],
    [Locale.enCA]: [Locale.enUS],
    [Locale.esUS]: [Locale.enUS],
    [Locale.enIN]: [Locale.enGB, Locale.enUS],
    [Locale.enNZ]: [Locale.enAU, Locale.enUS],
    [Locale.enSG]: [Locale.enAU, Locale.enUS],
    default: [Locale.enUS],
  },
  debug: false,
  react: { useSuspense: false },
});

export default i18n;
