const delimiter = "&";

export function encodeObject(object: { [key: string]: string }, queryStringName: string): string {
  if (object)
    return (
      Object.entries(object)
        ?.map(
          ([key, value]) =>
            `${encodeURIComponent(`${queryStringName}[${key}]`)}=${encodeURIComponent(value)}`,
        )
        .join(delimiter) ?? ""
    );

  return "";
}
