import { PricePerQuantity, RawPricePerQuantity, UnitPrice } from "../types/tieredPrices";

function isDiscounted(price: PricePerQuantity, isVatInclusive: boolean) {
  const listPrice = isVatInclusive ? price.totalListPrice.taxed : price.totalListPrice.untaxed;
  const discountedPrice = isVatInclusive
    ? price.totalDiscountedPrice.taxed
    : price.totalDiscountedPrice.untaxed;
  return listPrice !== discountedPrice;
}

function getPercentage(value: number, referenceValue: number) {
  return Math.round((1 - value / referenceValue) * 100);
}

function getUnitPrice(price: PricePerQuantity, isVatInclusive: boolean): UnitPrice {
  validatePricePerQuantity(price);
  return {
    unitPrice: isVatInclusive ? price.unitListPrice.taxed : price.unitListPrice.untaxed,
    unitDiscountedPrice: isVatInclusive
      ? price.unitDiscountedPrice.taxed
      : price.unitDiscountedPrice.untaxed,
    currency: price.currency,
  };
}

function validatePricePerQuantity(value: PricePerQuantity): void {
  const buildError = (details: string) => new Error(`Invalid PricePerQuantity: ${details}`);

  if (!value) throw buildError("object is null or undefined");

  const requiredFields: Array<keyof PricePerQuantity> = [
    "unitListPrice",
    "unitDiscountedPrice",
    "totalListPrice",
    "totalDiscountedPrice",
    "currency",
    "quantity",
  ];

  requiredFields.forEach((field) => {
    if (!value[field]) {
      throw buildError(`${field} is null or undefined [${JSON.stringify(value)}]`);
    }
  });
}

function buildPricePerQuantity(
  price: RawPricePerQuantity,
  quantity: number,
  includeSetUpFee: boolean,
  hidePerUnitPrice: boolean,
): PricePerQuantity {
  const pricePerQuantity = {
    quantity,
    currency: price.currency,
    totalListPrice: includeSetUpFee ? price.totalListPrice : price.breakdown.totalListPrice,
    totalDiscountedPrice: includeSetUpFee
      ? price.totalDiscountedPrice
      : price.breakdown.totalDiscountedPrice,
    additionalFees: price.breakdown.additionalFees,
  } as PricePerQuantity;

  if (!hidePerUnitPrice) {
    pricePerQuantity.unitListPrice = price.breakdown.unitListPrice;
    pricePerQuantity.unitDiscountedPrice = price.breakdown.unitDiscountedPrice;
  }

  return pricePerQuantity;
}

export {
  buildPricePerQuantity,
  getPercentage,
  getUnitPrice,
  isDiscounted,
  validatePricePerQuantity,
};
