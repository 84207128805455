export enum Locale {
  deDE = "de-DE",
  esES = "es-ES",
  enUS = "en-US",
  enGB = "en-GB",
  fiFI = "fi-FI",
  nlNL = "nl-NL",
  itIT = "it-IT",
  svSE = "sv-SE",
  nbNO = "nb-NO",
  ptPT = "pt-PT",
  daDK = "da-DK",
  frCA = "fr-CA",
  esUS = "es-US",
  enCA = "en-CA",
  nlBE = "nl-BE",
  enIE = "en-IE",
  frBE = "fr-BE",
  frFR = "fr-FR",
  frCH = "fr-CH",
  deAT = "de-AT",
  deCH = "de-CH",
  itCH = "it-CH",
  enAU = "en-AU",
  enNZ = "en-NZ",
  enSG = "en-SG",
  enIN = "en-IN",
}
