import { combineQuantities, getPriceQueryKey } from "../util/price-extraction";
import {
  BasePriceExtractionConfig,
  LowestPriceQuery,
  LowestPriceRequest,
  PriceQuery,
  ProductPriceDetailsStore,
} from "./productPriceDetails.types";

import { debounce } from "lodash";
import { create } from "zustand";
import { fetchProductPriceDetails } from "../clients/pricePlatformServiceClient";

// function to transform price query to lowest price query
const transformToLowestPriceQuery = (priceQuery: PriceQuery): LowestPriceQuery => {
  const { quantity, cacheableQuantities, ...rest } = priceQuery;
  const combinedQuantities = combineQuantities(cacheableQuantities, quantity);
  return {
    ...rest,
    quantity: combinedQuantities,
  };
};

// Debounced function to fetch product price details
const fetchProductPriceDetailsDebounced = debounce(async (getStore, setStore): Promise<void> => {
  const { priceQueryMap, basePriceExtractionConfig, pricingContext } = getStore();
  const priceQueries: LowestPriceQuery[] = Object.values(priceQueryMap as PriceQuery[]).map(
    transformToLowestPriceQuery,
  );
  const { tenant, locale, requestor, pageId } = basePriceExtractionConfig;
  if (!tenant || !locale || priceQueries.length === 0 || !pricingContext.isInitialized) return;
  
  try {
    // Locking the fetch and clearing the queries in one batch
    setStore({
      priceQueriesLoading: { ...priceQueryMap },
      priceQueryMap: {},
    });

    const request: LowestPriceRequest = {
      tenant,
      locale,
      requestor,
      pageId,
      priceQueries,
      pricingContext: pricingContext.value,
    };
    const data = await fetchProductPriceDetails(request);
    setStore((state: ProductPriceDetailsStore) => ({
      productPriceDetails: { ...state.productPriceDetails, ...data },
      priceQueriesLoading: {},
    }));
  } catch (error) {
    console.error("Error fetching product details for queries:", priceQueries);
  }
}, 100);

// Zustand store creation
const useProductStore = create<ProductPriceDetailsStore>((set, get) => ({
  productPriceDetails: {},
  priceQueryMap: {},
  priceQueriesLoading: {},
  setPricingContext: (pricingContext: string) => {
    set({ pricingContext: { value: pricingContext, isInitialized: true } });
  },
  setVatIncl: (vatIncl: boolean) => {
    set({ vatIncl: vatIncl });
  },
  setBasePriceExtractionConfig: (baseConfig: BasePriceExtractionConfig) => {
    set({
      basePriceExtractionConfig: { ...baseConfig, requestor: "price-platform" + baseConfig.pageId },
    });
  },
  addPriceQuery: async (priceQuery: PriceQuery): Promise<void> => {
    const { mpvId, productKey, version, quantity, cacheableQuantities, selections } = priceQuery;
    if (!mpvId && !(productKey && version)) return;
  
    const priceQueryId = getPriceQueryKey({ mpvId, productKey, version, quantity, cacheableQuantities, selections });

    // If data for this id is already fetched, dont add to mpvIdList
    if (get().productPriceDetails[priceQueryId] || get().priceQueriesLoading[priceQueryId]) return;
    priceQuery.id = priceQueryId;

    // requestedDisplayStrategy was added because of gallery, we will get this from backend for now
    // priceQuery.requestedDisplayStrategy =
    // priceQuery.requestedDisplayStrategy || PricePresentationType.QtyStartingAtPrice;

    set((state: { priceQueryMap: Record<string, PriceQuery> }) => ({
      priceQueryMap: { ...state.priceQueryMap, [priceQueryId]: priceQuery },
    }));
    fetchProductPriceDetailsDebounced(get, set);
  },
}));

export { useProductStore };
