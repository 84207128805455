import { LowestPriceRequest } from "../store/productPriceDetails.types";

// Base URL for the price platform service
const pricePlatformBaseUrl = "https://api.price-platform-service-cdn.pai.vpsvc.com/v1/pricing";

// Function to fetch product price details from the API
const fetchProductPriceDetails = async (
  request: LowestPriceRequest,
): Promise<Record<string, unknown>> => {
  // Make API call to fetch product price details using mpvIds
  // Quantities available in quantities array is passed to the API call to fetch the prices of each quantity in one go itself in case of multiple quantity options available for a product
  const url = `${pricePlatformBaseUrl}/tenant/${request.tenant}/locale/${request.locale}/lowest-price?requestor=${request.requestor}&pageId=${request.pageId}`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  });
  const responseData = await response.json();

  const priceDetails: Record<string, unknown> = {};
  responseData.forEach(
    (product: { id: string; mpvId: string; prices: unknown; displayStrategy: string }) => {
      priceDetails[product.id] = {
        prices: product.prices,
        /*
          This allows changing the requested strategy to configured strategy i.e. reconfigure
          requested strategy to something else [For future purposes only]
        */
        displayStrategy: product.displayStrategy,
      };
    },
  );

  return priceDetails;
};

export { fetchProductPriceDetails };
