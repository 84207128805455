import { SkeletonText, SkeletonTextLine } from "@vp/swan";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import {
  getAndExtractMerchandisingQuantities,
  MerchandisingQuantities,
} from "../../clients/catalogTransitionClient";
import { getPricePerQuantity } from "../../clients/websitePricingClient";
import usePricingContextInitialization from "../../hooks/usePricingContextInitialization";
import { useProductStore } from "../../store/productPriceDetails.store";
import { PricePerQuantity } from "../../types/tieredPrices";
import { PriceDisplayProps } from "../PriceDisplay";
import { PriceForQuantityDisplay } from "./PriceForQuantityDisplay";
import { RangedPriceDisplay } from "./RangedPriceDisplay";

export type TieredPriceDisplayProps = PriceDisplayProps;

// 1. Accept essential parameters: productId, productVersion, and optional selections and quantity.
// 2. Accept boolean flags to determine whether to display additional information like setup fees or minimum order quantities.
// 3. Fetch pricing details from the website pricing API based on the provided product information.
// 4. Render either the range price component or the price for a specific quantity, depending on whether the quantity.

const TieredPriceDisplay = ({
  selections,
  version,
  productKey,
  quantity,
  defaultPricingInfo,
  hideSetupFee,
  hidePerUnitPrice,
  hideVolumeDiscount,
  hideMinimumOrderableQuantity,
}: TieredPriceDisplayProps) => {
  const pricingContext = useProductStore((state) => state.pricingContext)?.value;
  const basePriceExtractionConfig = useProductStore((state) => state.basePriceExtractionConfig);

  const [priceMoq, setPriceMoq] = useState<PricePerQuantity>();
  const [merchandisingQuantities, setMerchandisingQuantities] = useState<MerchandisingQuantities>();

  const {
    locale = "",
    tenant = "",
    requestor = "",
    developmentMode = false,
  } = basePriceExtractionConfig || {};
  const market = locale.substring(3); // Extract market from locale

  const pricingContextInitialized = usePricingContextInitialization(
    tenant,
    locale,
    developmentMode,
  );

  useEffect(() => {
    const fetchPricingInfo = async () => {
      if (productKey && version) {
        // Fetch and extract merchandising quantities
        const merchandisingQuantities = await getAndExtractMerchandisingQuantities(
          productKey,
          version,
          tenant,
          market,
          requestor,
          selections || {},
        );
        setMerchandisingQuantities(merchandisingQuantities);

        if (merchandisingQuantities.moq) {
          // Fetch price for minimum orderable quantity
          const priceMoq: PricePerQuantity = await getPricePerQuantity(
            merchandisingQuantities.moq,
            productKey,
            version,
            tenant,
            market,
            false, // do not include setup fee
            requestor,
            selections,
            pricingContext,
            defaultPricingInfo?.priceMoq,
          );
          setPriceMoq(priceMoq);
        }
      }
    };
    if (pricingContextInitialized && tenant && market) {
      fetchPricingInfo();
    }
  }, [
    productKey,
    version,
    tenant,
    selections,
    requestor,
    market,
    pricingContext,
    pricingContextInitialized,
    defaultPricingInfo?.priceMoq,
  ]);

  if (!basePriceExtractionConfig || !pricingContextInitialized) {
    return (
      <SkeletonText aria-label="loading">
        <SkeletonTextLine width="narrow"></SkeletonTextLine>
      </SkeletonText>
    );
  }

  if (productKey && version && merchandisingQuantities && priceMoq) {
    // Show price for quantity only when quantity is greater than or equal to minimum orderable quantity
    return (
      <>
        {quantity && quantity >= merchandisingQuantities.moq ? (
          <PriceForQuantityDisplay
            productKey={productKey}
            version={version}
            selections={selections}
            quantity={quantity}
            priceMoq={priceMoq}
            defaultPricingInfo={defaultPricingInfo}
            hideSetupFee={hideSetupFee}
            hidePerUnitPrice={hidePerUnitPrice}
            hideVolumeDiscount={hideVolumeDiscount}
          />
        ) : (
          <RangedPriceDisplay
            productKey={productKey}
            version={version}
            selections={selections}
            merchandisingQuantities={merchandisingQuantities}
            priceMoq={priceMoq}
            defaultPricingInfo={defaultPricingInfo}
            hideSetupFee={hideSetupFee}
            hideMinimumOrderableQuantity={hideMinimumOrderableQuantity}
          />
        )}
      </>
    );
  }

  return <></>;
};

// Prop type validation for TieredPriceDisplay component
TieredPriceDisplay.propTypes = {
  productKey: PropTypes.string.isRequired,
  version: PropTypes.number.isRequired,
  quantity: PropTypes.number,
  selections: PropTypes.any,
  defaultPricingInfo: PropTypes.any,
  hideSetupFee: PropTypes.bool,
  hidePerUnitPrice: PropTypes.bool,
  hideVolumeDiscount: PropTypes.bool,
  hideMinimumOrderableQuantity: PropTypes.bool,
};

export { TieredPriceDisplay };
