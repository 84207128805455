import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { I18nextProvider } from "react-i18next";

import { MerchandisingQuantities } from "../../clients/catalogTransitionClient";
import { getMaxTierQuantity, getPricePerQuantity } from "../../clients/websitePricingClient";
import i18n from "../../localization/i18next";
import { useProductStore } from "../../store/productPriceDetails.store";
import { Price, PricePresentationType, PriceProperties } from "../../types/prices";
import { PricePerQuantity, RangePrice } from "../../types/tieredPrices";
import { PriceDisplayProps } from "../PriceDisplay";
import { SmallFontWrapper } from "../PricePlatform.styles";
import PricePresentation from "../PricePresentation";
import { Moq } from "./Moq";
import { SetupFee } from "./SetupFee";

export interface RangedPriceDisplayProps extends PriceDisplayProps {
  productKey: string;
  version: number;
  merchandisingQuantities: MerchandisingQuantities;
  priceMoq: PricePerQuantity;
  hideSetupFee?: boolean;
  hideMinimumOrderableQuantity?: boolean;
}

// 1. Accept essential parameters: productKey, version, priceMoq, merchandisingQuantities, and optional flags for showing setup fee and minimum order quantity.
// 2. Extract relevant pricing context and configurations from the product store.
// 3. Added an effect hook to fetch pricing information based on the product details and configurations.
// 4. If range prices are available, render the price presentation component with the fetched pricing data and any additional details as needed.

const RangedPriceDisplay = ({
  selections,
  version,
  productKey,
  priceMoq,
  merchandisingQuantities,
  defaultPricingInfo,
  hideSetupFee,
  hideMinimumOrderableQuantity,
}: RangedPriceDisplayProps) => {
  const pricingContext = useProductStore((state) => state.pricingContext)?.value;
  const basePriceExtractionConfig = useProductStore((state) => state.basePriceExtractionConfig);

  const [rangePrices, setRangePrices] = useState<RangePrice>();
  const [setupFee, setSetupFee] = useState<Price>();

  const { locale = "", tenant = "", requestor = "", pageId } = basePriceExtractionConfig || {};
  const vatIncl = useProductStore((state) => state.vatIncl) || false;
  const market = locale.substring(3); // Extract market from locale

  const [priceProperties, setPriceProperties] = useState<PriceProperties>({
    minimumFractionDigits: 2,
    locale: locale,
    currency: defaultPricingInfo?.currency || "",
    vatInc: Boolean(vatIncl),
  });

  useEffect(() => {
    const fetchPricingInfo = async () => {
      const maxTieredQuantity = await getMaxTierQuantity(
        productKey,
        version,
        tenant,
        market,
        requestor,
        selections,
        pricingContext,
      );

      // maxTieredQuantity: Maximum orderable quantity from website pricing
      // merchandisingQuantities.maximumOrderQuantity: maximum quantity from catalog transition api
      const maximumQuantity = maxTieredQuantity
        ? Math.min(maxTieredQuantity, merchandisingQuantities.maximumOrderQuantity)
        : merchandisingQuantities.maximumOrderQuantity;

      // Fetch price for maximum quantity wihtout setup fee
      const priceMaxQuantity: PricePerQuantity = await getPricePerQuantity(
        maximumQuantity,
        productKey,
        version,
        tenant,
        market,
        false, // do not include setup fee
        requestor,
        selections,
        pricingContext,
        defaultPricingInfo?.priceMaxQuantity,
      );

      if (priceMaxQuantity && priceMoq) {
        setSetupFee(
          priceMoq.additionalFees.setupFee.listPrice ||
            priceMaxQuantity.additionalFees.setupFee.listPrice ||
            defaultPricingInfo?.setupFee,
        );
        setPriceProperties((prev) => ({
          ...prev,
          currency: priceMaxQuantity.currency,
          vatInc: vatIncl,
        }));
        setRangePrices({
          lowestListPrice: priceMaxQuantity.unitListPrice,
          highestListPrice: priceMoq.unitListPrice,
          lowestDiscountPrice: priceMaxQuantity.unitDiscountedPrice,
          highestDiscountPrice: priceMoq.unitDiscountedPrice,
        });
      }
    };
    if (tenant && market) {
      fetchPricingInfo();
    }
  }, [
    productKey,
    version,
    tenant,
    selections,
    requestor,
    market,
    pricingContext,
    priceMoq,
    vatIncl,
    merchandisingQuantities,
    defaultPricingInfo?.priceMaxQuantity,
    defaultPricingInfo?.setupFee,
  ]);

  return (
    <I18nextProvider i18n={i18n}>
      {rangePrices && (
        <>
          <PricePresentation
            presentationType={PricePresentationType.RangePrice}
            prices={rangePrices}
            priceProperties={priceProperties}
            pageId={pageId}
          />
          <SmallFontWrapper>
            {!hideMinimumOrderableQuantity && merchandisingQuantities.moq && (
              <Moq moq={merchandisingQuantities.moq} />
            )}
            {!hideSetupFee && setupFee && (
              <SetupFee setupFee={setupFee as Price} priceProperties={priceProperties} />
            )}
          </SmallFontWrapper>
        </>
      )}
    </I18nextProvider>
  );
};

RangedPriceDisplay.propTypes = {
  productKey: PropTypes.string.isRequired,
  version: PropTypes.number.isRequired,
  quantity: PropTypes.number,
  selections: PropTypes.any,
  defaultPricingInfo: PropTypes.any,
  hideSetupFee: PropTypes.bool,
  hideMinimumOrderableQuantity: PropTypes.bool,
  merchandisingQuantities: PropTypes.any,
  priceMoq: PropTypes.any,
};

export { RangedPriceDisplay };
