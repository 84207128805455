import { ErrorBoundary } from "@vp/swan";
import React, { ReactNode } from "react";

export interface PriceErrorBoundaryProps {
  children: ReactNode;
  fallbackUi?: ReactNode;
}

const PriceErrorBoundary = ({ children, fallbackUi }: PriceErrorBoundaryProps) => {
  return <ErrorBoundary fallback={fallbackUi}>{children}</ErrorBoundary>;
};

export default PriceErrorBoundary;
