export enum PageId {
  CorePDP = "CorePdp",
  LatPDP = "LatPdp",
  PDP = "PDP",
  Gallery = "Gallery",
  ChecksQuickView = "QuickViewAtc",
  PDC = "PDC",
  QuickEditor = "QuickEditor",
  Cart = "Cart",
  Category = "Category",
  Checkout = "Checkout",
  OrderConfirmation = "OrderConfirmation",
  HomePage = "HomePage",
  SearchPages = "SearchPages",
  Studio = "Studio",
  QuantitySelectionPage = "QuantitySelectionPage",
  UploadFlowDialogComponent = "UploadFlowDialogComponent",
  Discovery = "Discovery",
  PLP = "PLP",
  GalleryQuickView = "GalleryQuickView",
  Favorites = "Favorites",
  LaunchMyBusiness = "LaunchMyBusiness",
}
