import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { I18nextProvider } from "react-i18next";

import { getPricePerQuantity } from "../../clients/websitePricingClient";
import i18n from "../../localization/i18next";
import { useProductStore } from "../../store/productPriceDetails.store";
import { PricePresentationType, PriceProperties } from "../../types/prices";
import { PriceDetails, PricePerQuantity } from "../../types/tieredPrices";
import { PriceDisplayProps } from "../PriceDisplay";
import { SmallFontWrapper } from "../PricePlatform.styles";
import PricePresentation from "../PricePresentation";
import Savings from "./Savings";
import { SetupFee } from "./SetupFee";

export interface PriceForQuantityDisplayProps extends PriceDisplayProps {
  productKey: string;
  version: number;
  quantity: number;
  priceMoq: PricePerQuantity;
  hideSetupFee?: boolean;
  hidePerUnitPrice?: boolean;
  hideVolumeDiscount?: boolean;
}

// 1. Accept essential parameters: productKey, version, quantity, priceMoq, and optional flags for displaying volume discounts and setup fees.
// 2. Extract relevant pricing context and configurations from the product store.
// 3. Added an effect hook to fetch pricing information when the quantity is specified and greater than zero.
// 4. Retrieve the price per specified quantity, including any applicable setup fees, from the website pricing API.
// 5. If price information is available, render the price presentation component, along with optional savings and setup fee components based on the provided flags.

const PriceForQuantityDisplay = ({
  selections,
  version,
  productKey,
  quantity,
  priceMoq,
  defaultPricingInfo,
  hideSetupFee,
  hidePerUnitPrice,
  hideVolumeDiscount,
}: PriceForQuantityDisplayProps) => {
  const [priceDetails, setPriceDetails] = useState<PriceDetails>();
  const [priceForQtyUnits, setPriceForQtyUnits] = useState<PricePerQuantity>();
  const [isSetupFeeIncluded, setIsSetupFeeIncluded] = useState(false);
  const pricingContext = useProductStore((state) => state.pricingContext)?.value;
  const basePriceExtractionConfig = useProductStore((state) => state.basePriceExtractionConfig);

  const { locale = "", tenant = "", requestor = "", pageId } = basePriceExtractionConfig || {};
  const vatIncl = useProductStore((state) => state.vatIncl) || false;
  const market = locale.substring(3); // Extract market from locale

  const [priceProperties, setPriceProperties] = useState<PriceProperties & { quantity: number }>({
    minimumFractionDigits: 2,
    locale: locale,
    currency: defaultPricingInfo?.currency || "",
    vatInc: Boolean(vatIncl),
    quantity: quantity,
  });

  useEffect(() => {
    const fetchPricingInfo = async () => {
      const pricePerQuantity: PricePerQuantity = await getPricePerQuantity(
        quantity,
        productKey,
        version,
        tenant,
        market,
        true, // calculate price with setupfee
        requestor,
        selections,
        pricingContext,
        defaultPricingInfo?.priceForQuantity,
        hidePerUnitPrice,
      );

      // Total price will contain the setup fee included
      if (pricePerQuantity) {
        if (pricePerQuantity.additionalFees.setupFee.listPrice.taxed !== 0) {
          setIsSetupFeeIncluded(true);
        }

        // Update price properties and details
        setPriceProperties((prev) => ({
          ...prev,
          currency: pricePerQuantity.currency,
          vatInc: vatIncl,
          quantity: quantity,
        }));
        setPriceForQtyUnits(pricePerQuantity);
        setPriceDetails({
          isVatInclusive: vatIncl,
          UnitListPriceForMoq: priceMoq.unitListPrice,
          priceForQuantity: pricePerQuantity,
        });
      }
    };
    if (quantity > 0 && tenant && market) {
      fetchPricingInfo();
    }
  }, [
    quantity,
    productKey,
    version,
    tenant,
    market,
    hidePerUnitPrice,
    requestor,
    selections,
    vatIncl,
    priceMoq,
    pricingContext,
    defaultPricingInfo?.priceForQuantity,
  ]);

  return (
    <I18nextProvider i18n={i18n}>
      {priceForQtyUnits && (
        <>
          <PricePresentation
            presentationType={PricePresentationType.PriceWithQtyAndUnit}
            prices={{ ...priceForQtyUnits, unitListPrice: priceMoq.unitListPrice }} // update this to calculate discounted unit price
            priceProperties={priceProperties}
            pageId={pageId}
          />
          <SmallFontWrapper>
            {!hideVolumeDiscount && priceDetails && (
              <Savings priceDetails={priceDetails as PriceDetails} />
            )}
            {!hideSetupFee && <SetupFee isSetupFeeIncluded={isSetupFeeIncluded} />}
          </SmallFontWrapper>
        </>
      )}
    </I18nextProvider>
  );
};

PriceForQuantityDisplay.propTypes = {
  productKey: PropTypes.string.isRequired,
  version: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  selections: PropTypes.any,
  defaultPricingInfo: PropTypes.any,
  hidePerUnitPrice: PropTypes.bool,
  hideSetupFee: PropTypes.bool,
  hideVolumeDiscount: PropTypes.bool,
  priceMoq: PropTypes.any,
};

export { PriceForQuantityDisplay };
