import {
  IPrice,
  IQuantityPrice,
  IRangePrice,
  IUnitPrice,
  IVatMessage, IPlusOrMinus, 
} from "@vp/vp-js-token-engine";

import {
  Price,
  PricePresentationType,
  PriceProperties,
  PricesTypesBasedOnPricePresentation,
} from "../types/prices";

export const getPricePerUnit = (
  prices: Record<PricesTypesBasedOnPricePresentation<PricePresentationType.PricePerUnit>, Price>,
  priceProperties: PriceProperties,
): IUnitPrice => {
  const taxInclusivity = priceProperties.vatInc ? "taxed" : "untaxed";

  return {
    unitListPrice: prices.unitListPrice[taxInclusivity],
    unitDiscountPrice: prices.unitDiscountedPrice[taxInclusivity],
    minimumFractionDigits: priceProperties.minimumFractionDigits,
    culture: priceProperties.locale,
    currency: priceProperties.currency,
    vatInc: priceProperties.vatInc,
    hideVatMessage: priceProperties.hideVatMessage,
    hideShippingMessageInVatMessage: priceProperties.hideShippingMessageInVatMessage,
  };
};

export const getQuantityPrice = (
  prices: Record<
    PricesTypesBasedOnPricePresentation<PricePresentationType.QtyStartingAtPrice>,
    Price
  >,
  priceProperties: PriceProperties & { quantity: number },
): IQuantityPrice => {
  const taxInclusivity = priceProperties.vatInc ? "taxed" : "untaxed";

  return {
    quantity: priceProperties.quantity,
    listPrice: prices.totalListPrice[taxInclusivity],
    discountPrice: prices.totalDiscountedPrice[taxInclusivity],
    minimumFractionDigits: priceProperties.minimumFractionDigits,
    culture: priceProperties.locale,
    currency: priceProperties.currency,
    vatInc: priceProperties.vatInc,
    hideVatMessage: priceProperties.hideVatMessage,
    hideShippingMessageInVatMessage: priceProperties.hideShippingMessageInVatMessage,
  };
};

export const getQuantityFromPrice = (
  prices: Record<PricesTypesBasedOnPricePresentation<PricePresentationType.QtyFromPrice>, Price>,
  priceProperties: PriceProperties & { quantity: number },
): IQuantityPrice => {
  const taxInclusivity = priceProperties.vatInc ? "taxed" : "untaxed";
  return {
    quantity: priceProperties.quantity,
    listPrice: prices.totalListPrice[taxInclusivity],
    discountPrice: prices.totalDiscountedPrice[taxInclusivity],
    minimumFractionDigits: priceProperties.minimumFractionDigits,
    culture: priceProperties.locale,
    currency: priceProperties.currency,
    vatInc: priceProperties.vatInc,
    hideVatMessage: priceProperties.hideVatMessage,
    hideShippingMessageInVatMessage: priceProperties.hideShippingMessageInVatMessage,
  };
};

export const getRangedPrice = (
  prices: Record<PricesTypesBasedOnPricePresentation<PricePresentationType.RangePrice>, Price>,
  priceProperties: PriceProperties,
): IRangePrice => {
  const taxInclusivity = priceProperties.vatInc ? "taxed" : "untaxed";

  return {
    lowestListPrice: prices.lowestListPrice[taxInclusivity],
    highestListPrice: prices.highestListPrice[taxInclusivity],
    lowestDiscountPrice: prices.lowestDiscountPrice[taxInclusivity],
    highestDiscountPrice: prices.highestDiscountPrice[taxInclusivity],
    minimumFractionDigits: priceProperties.minimumFractionDigits,
    culture: priceProperties.locale,
    currency: priceProperties.currency,
    vatInc: priceProperties.vatInc,
    hideVatMessage: priceProperties.hideVatMessage,
    hideShippingMessageInVatMessage: priceProperties.hideShippingMessageInVatMessage,
  };
};

export const getPriceWithQtyAndUnit = (
  prices: Record<
    PricesTypesBasedOnPricePresentation<PricePresentationType.PriceWithQtyAndUnit>,
    Price
  >,
  priceProperties: PriceProperties & { quantity: number },
): IQuantityPrice & IUnitPrice => {
  const taxInclusivity = priceProperties.vatInc ? "taxed" : "untaxed";
  return {
    quantity: priceProperties.quantity,
    listPrice: prices.totalListPrice?.[taxInclusivity] ?? 0,
    discountPrice: prices.totalDiscountedPrice?.[taxInclusivity] ?? 0,
    unitListPrice: prices.unitListPrice?.[taxInclusivity],
    unitDiscountPrice: prices.unitDiscountedPrice?.[taxInclusivity],
    minimumFractionDigits: priceProperties.minimumFractionDigits,
    culture: priceProperties.locale,
    currency: priceProperties.currency,
    vatInc: priceProperties.vatInc,
    hideVatMessage: priceProperties.hideVatMessage,
    hideShippingMessageInVatMessage: priceProperties.hideShippingMessageInVatMessage,
  };
};

export const getPrice = (
  prices: Record<PricesTypesBasedOnPricePresentation<PricePresentationType.RawPrice>, Price>,
  priceProperties: PriceProperties,
): IPrice => {
  const taxInclusivity = priceProperties.vatInc ? "taxed" : "untaxed";

  return {
    listPrice: prices.totalListPrice[taxInclusivity],
    discountPrice: prices.totalDiscountedPrice[taxInclusivity],
    minimumFractionDigits: priceProperties.minimumFractionDigits,
    culture: priceProperties.locale,
    currency: priceProperties.currency,
    vatInc: priceProperties.vatInc,
    hideVatMessage: priceProperties.hideVatMessage,
    hideShippingMessageInVatMessage: priceProperties.hideShippingMessageInVatMessage,
  };
};

export const getRawAndUnitPrice = (
  prices: Record<
    PricesTypesBasedOnPricePresentation<PricePresentationType.RawAndPricePerUnit>,
    Price
  >,
  priceProperties: PriceProperties,
): IPrice & IUnitPrice => {
  const taxInclusivity = priceProperties.vatInc ? "taxed" : "untaxed";

  return {
    listPrice: prices.totalListPrice[taxInclusivity],
    discountPrice: prices.totalDiscountedPrice[taxInclusivity],
    unitListPrice: prices.unitListPrice[taxInclusivity],
    unitDiscountPrice: prices.unitDiscountedPrice[taxInclusivity],
    minimumFractionDigits: priceProperties.minimumFractionDigits,
    culture: priceProperties.locale,
    currency: priceProperties.currency,
    vatInc: priceProperties.vatInc,
    hideVatMessage: priceProperties.hideVatMessage,
    hideShippingMessageInVatMessage: priceProperties.hideShippingMessageInVatMessage,
  };
};

export const getAsLowAsPerPiecePrice = (
  prices: Record<
    PricesTypesBasedOnPricePresentation<PricePresentationType.AsLowAsPricePerPiece>,
    Price
  >,
  priceProperties: PriceProperties,
): IUnitPrice => {
  const taxInclusivity = priceProperties.vatInc ? "taxed" : "untaxed";

  return {
    unitListPrice: prices.unitListPrice[taxInclusivity],
    unitDiscountPrice: prices.unitDiscountedPrice[taxInclusivity],
    minimumFractionDigits: priceProperties.minimumFractionDigits,
    culture: priceProperties.locale,
    currency: priceProperties.currency,
    vatInc: priceProperties.vatInc,
    hideVatMessage: priceProperties.hideVatMessage,
    hideShippingMessageInVatMessage: priceProperties.hideShippingMessageInVatMessage,
  };
};

export const getPlusMinusPrice = (
  prices: Record<PricesTypesBasedOnPricePresentation<PricePresentationType.PlusOrMinus>, number>,
  priceProperties: PriceProperties,
): IPlusOrMinus => {
  return {
    priceDifference: priceProperties.isPriceNegative
      ? -Math.abs(prices.priceDifference)
      : prices.priceDifference,
    priceDifferenceDiscounted: priceProperties.isPriceNegative
      ? -Math.abs(prices.priceDifferenceDiscounted)
      : prices.priceDifferenceDiscounted,
    minimumFractionDigits: priceProperties.minimumFractionDigits,
    culture: priceProperties.locale,
    currency: priceProperties.currency,
    zeroDisplayType: priceProperties.zeroDisplayType,
    comparativeDisplayType: priceProperties.comparativeDisplayType,
  };
};

export const getVatInfo = (
  priceProperties: Pick<PriceProperties, "locale" | "vatInc" | "hideShippingMessageInVatMessage">,
): IVatMessage => {
  return {
    culture: priceProperties.locale,
    vatInc: priceProperties.vatInc,
    hideShippingMessageInVatMessage: priceProperties.hideShippingMessageInVatMessage,
  };
};
