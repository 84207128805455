import React from "react";

import { LowestPriceDisplay } from "../LowestPriceDisplay/LowestPriceDisplay";
import { PriceDisplayProps } from "../PriceDisplay";
import { TieredPriceDisplay } from "../TieredPriceDisplay/TieredPriceDisplay";

// 1. Accept details like mpvId, selections (optionally), quantity (optionally) or quantityEnum (optionally),
// 2. Fetch price details from store
// 3. Pass the price details to this component

export type PriceExtractionWrapperProps = PriceDisplayProps;

const PriceExtractionWrapper = ({
  mpvId,
  productKey,
  version,
  quantity,
  cacheableQuantities,
  selections,
  requestedDisplayStrategy,
  isQuantitySliderEnabled,
  defaultPricingInfo,
  hideSetupFee,
  hidePerUnitPrice,
  hideVolumeDiscount,
  hideMinimumOrderableQuantity,
}: PriceExtractionWrapperProps) => {
  return (
    <>
      {isQuantitySliderEnabled ? (
        <TieredPriceDisplay
          selections={selections}
          version={version}
          productKey={productKey}
          quantity={quantity}
          defaultPricingInfo={defaultPricingInfo}
          hideSetupFee={hideSetupFee}
          hidePerUnitPrice={hidePerUnitPrice}
          hideVolumeDiscount={hideVolumeDiscount}
          hideMinimumOrderableQuantity={hideMinimumOrderableQuantity}
        />
      ) : (
        <LowestPriceDisplay
          mpvId={mpvId}
          selections={selections}
          version={version}
          productKey={productKey}
          quantity={quantity}
          cacheableQuantities={cacheableQuantities}
          requestedDisplayStrategy={requestedDisplayStrategy}
        />
      )}
    </>
  );
};

export { PriceExtractionWrapper };
