import React from "react";

import { PriceQuery } from "../../store/productPriceDetails.types";
import { PricePresentationType } from "../../types/prices";
import { PricingInfo } from "../../types/tieredPrices";
import CSRWrapper from "../CSRWrapper";
import PriceErrorBoundary from "../PriceErrorBoundary";
import { PriceExtractionWrapper } from "../PriceExtraction";

// 1. Accept details like mpvId, requestor, displayStrategy, selections (optionally), quantity (optionally) or quantityEnum (optionally),
// 2. Pass the details to PriceExtractionWrapper

export interface PriceDisplayProps extends PriceQuery {
  priceDisplayStrategy?: PricePresentationType;
  isQuantitySliderEnabled?: boolean;
  defaultPricingInfo?: PricingInfo;
  hideSetupFee?: boolean;
  hidePerUnitPrice?: boolean;
  hideVolumeDiscount?: boolean;
  hideMinimumOrderableQuantity?: boolean;
}

/**
 * quantity param is an optional param which comprises the selected quantity for a product.
 * cacheableQuantities param is an optional param comprising array of all available quantities for a product
 * This param is optional since optimising by caching multi quantity price is an optional improvement. This is also relevant only to pages that have the usecase to quickly switch between multiple quantities with the same selection criteria.
 */
const PriceDisplay = ({
  mpvId,
  productKey,
  version,
  quantity,
  cacheableQuantities,
  selections,
  priceDisplayStrategy,
  isQuantitySliderEnabled = false,
  defaultPricingInfo,
  hideSetupFee = false,
  hidePerUnitPrice = false,
  hideVolumeDiscount = false,
  hideMinimumOrderableQuantity = false,
}: PriceDisplayProps) => {
  return (
    //CSRWrapper added to avoid hydration error glitches for SSR rendered pages
    <CSRWrapper serverComponent={<></>}>
      <PriceErrorBoundary fallbackUi={<></>}>
        <PriceExtractionWrapper
          mpvId={mpvId}
          productKey={productKey}
          quantity={quantity}
          cacheableQuantities={cacheableQuantities}
          selections={selections}
          version={version}
          requestedDisplayStrategy={priceDisplayStrategy}
          isQuantitySliderEnabled={isQuantitySliderEnabled}
          defaultPricingInfo={defaultPricingInfo}
          hideSetupFee={hideSetupFee}
          hidePerUnitPrice={hidePerUnitPrice}
          hideVolumeDiscount={hideVolumeDiscount}
          hideMinimumOrderableQuantity={hideMinimumOrderableQuantity}
        />
      </PriceErrorBoundary>
    </CSRWrapper>
  );
};

export default PriceDisplay;
