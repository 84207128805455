import pricingContextModuleHelpers from "@vp/pricing-context-module/helpers";
import { useEffect, useState } from "react";

import { useProductStore } from "../store/productPriceDetails.store";
import {
  getPricingContext,
  getPricingContextVatInclusivity,
  isPricingContextInitialized,
} from "../util/pricing-context";

const usePricingContextInitialization = (
  tenant: string,
  locale: string,
  developmentMode?: boolean,
) => {
  const [pricingContextInitialized, setPricingContextInitialized] = useState(false);

  const setPricingContext = useProductStore((state) => state.setPricingContext);
  const setVatIncl = useProductStore((state) => state.setVatIncl);

  useEffect(() => {
    const handleInitializedEvent = () => {
      const initialized = isPricingContextInitialized(developmentMode);
      setPricingContextInitialized(initialized);
      if (initialized) {
        setPricingContext(getPricingContext());
        setVatIncl(getPricingContextVatInclusivity());
      }
    };

    const updateVatInclusivity = () => {
      setVatIncl(getPricingContextVatInclusivity());
    };

    const updatePricingContext = () => {
      setPricingContext(getPricingContext());
    };
    /** init event listener to be added only if pricing context was not initialized yet */
    if (isPricingContextInitialized(developmentMode)) {
      handleInitializedEvent();
    } else {
      document.addEventListener(
        pricingContextModuleHelpers.EVENTS.INITIALIZED_EVENT,
        handleInitializedEvent,
      );
    }
    document.addEventListener(
      pricingContextModuleHelpers.EVENTS.VAT_INCLUSIVITY_CHANGE_EVENT,
      updateVatInclusivity,
    );
    document.addEventListener(
      pricingContextModuleHelpers.EVENTS.CONTEXT_CHANGE_EVENT,
      updatePricingContext,
    );

    return () => {
      if (!isPricingContextInitialized(developmentMode)) {
        document.removeEventListener(
          pricingContextModuleHelpers.EVENTS.INITIALIZED_EVENT,
          handleInitializedEvent,
        );
      }
      document.removeEventListener(
        pricingContextModuleHelpers.EVENTS.VAT_INCLUSIVITY_CHANGE_EVENT,
        updateVatInclusivity,
      );
      document.removeEventListener(
        pricingContextModuleHelpers.EVENTS.CONTEXT_CHANGE_EVENT,
        updatePricingContext,
      );
    };
  }, [tenant, locale, setPricingContext, setVatIncl, developmentMode]);

  return pricingContextInitialized;
};

export default usePricingContextInitialization;
