import { encodeObject } from "../util/httpUtils";

const BASE_URL = "https://catalog-transition.products.vpsvc.com/api/";

interface Quantities {
  availableQuantities: {
    list?: number[];
    range?: Range[];
  };
}

interface Range {
  minimum: number;
  increment?: number;
  maximum?: number;
}

export interface MerchandisingQuantities {
  moq: number;
  maximumOrderQuantity: number;
}

async function getMerchandisingQuantities(
  productKey: string,
  productVersion: number,
  tenant: string,
  country: string,
  requestor: string,
  selectedOptions: Record<string, string>,
): Promise<Quantities> {
  const url = `${BASE_URL}v3/products/${productKey}/${productVersion}/quantities/merchandising?merchant=${tenant}&market=${country}&requestor=${requestor}&${encodeObject(
    selectedOptions,
    "selectedOptions",
  )}`;

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data: Quantities = await response.json();
    return data;
  } catch (error) {
    console.error(error, url);
    return { availableQuantities: {} };
  }
}

function extractMerchandisingQuantities(quantities: Quantities): MerchandisingQuantities {
  const DEFAULT_MAX_RANGE_QUANTITY = 100000;
  if (!quantities?.availableQuantities) {
    return {
      moq: -1,
      maximumOrderQuantity: DEFAULT_MAX_RANGE_QUANTITY,
    };
  }
  const { availableQuantities } = quantities;
  if (availableQuantities?.range?.length) {
    return {
      moq: availableQuantities.range[0].minimum,
      maximumOrderQuantity: availableQuantities.range[0].maximum || DEFAULT_MAX_RANGE_QUANTITY,
    };
  }
  if (availableQuantities?.list?.length) {
    const quantityList = availableQuantities.list;
    return {
      moq: quantityList[0],
      maximumOrderQuantity: quantityList[quantityList.length - 1],
    };
  }
  return {
    moq: -1,
    maximumOrderQuantity: DEFAULT_MAX_RANGE_QUANTITY,
  };
}

export async function getAndExtractMerchandisingQuantities(
  productKey: string,
  productVersion: number,
  tenant: string,
  country: string,
  requestor: string,
  selectedOptions: Record<string, string>,
): Promise<MerchandisingQuantities> {
  const quantities = await getMerchandisingQuantities(
    productKey,
    productVersion,
    tenant,
    country,
    requestor,
    selectedOptions,
  );
  return extractMerchandisingQuantities(quantities);
}
