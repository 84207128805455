import { VatMessage } from "@vp/vp-tokenized-fragment";
import React from "react";

import { PriceProperties } from "../../types/prices";
import { getVatInfo } from "../../util/price-transform";
import { SetVersionOnWindow } from "../../util/version-logger";
import PriceErrorBoundary from "../PriceErrorBoundary";

//Aceept priceProperties and display VAT Message
interface TaxPresentationProps {
  priceProperties: Pick<PriceProperties, "locale" | "vatInc" | "hideShippingMessageInVatMessage">;
  placeHolderText?: string;
  className?: string;
}

const TaxPresentation = ({
  priceProperties,
  placeHolderText = "...",
  className,
}: TaxPresentationProps) => {
  const priceInfo = getVatInfo(priceProperties);
  SetVersionOnWindow();

  return (
    <PriceErrorBoundary fallbackUi={<></>}>
      <VatMessage
        placeHolderText={placeHolderText}
        pricingInfo={priceInfo}
        classNameWrapper={className}
      />
    </PriceErrorBoundary>
  );
};

export { TaxPresentation };
export type { TaxPresentationProps };
export default TaxPresentation;
