import { Box, Typography } from "@vp/swan";
import { RawPrice, VatMessage } from "@vp/vp-tokenized-fragment";
import { t } from "i18next";
import React from "react";

import { useProductStore } from "../../store/productPriceDetails.store";
import { Price, PriceProperties } from "../../types/prices";

interface SetupFeeProps {
  setupFee?: Price;
  priceProperties?: PriceProperties;
  isSetupFeeIncluded?: boolean;
}

// Case 1: Display the setup fee when using ranged prices.
// Case 2: Include the setup fee when displaying the price for a specific quantity.
// Case 3: If the setup fee is $0, indicate that no setup fee applies.

export const SetupFee = ({
  setupFee,
  priceProperties,
  isSetupFeeIncluded = false,
}: SetupFeeProps) => {
  const { tenant = "" } = useProductStore((state) => state.basePriceExtractionConfig) || {};
  const setupFeeValue = priceProperties?.vatInc ? setupFee?.taxed : setupFee?.untaxed;
  const hasSetupFee = setupFeeValue !== 0;

  if (isSetupFeeIncluded) {
    return (
      <Typography>
        <Box>{t("price-platform.includes-setup-fee")}</Box>
      </Typography>
    );
  }

  return (
    <Typography>
      <Box>
        {hasSetupFee && priceProperties && setupFeeValue ? (
          <Typography display="inline-block">
            <RawPrice
              placeHolderText="..."
              pricingInfo={{
                listPrice: setupFeeValue,
                discountPrice: setupFeeValue,
                minimumFractionDigits: 2,
                culture: priceProperties.locale,
                currency: priceProperties.currency,
                vatInc: priceProperties.vatInc,
                hideVatMessage: true,
              }}
              tenant={tenant}
            />{" "}
            {t("price-platform.setup-fee")}{" "}
            <VatMessage
              pricingInfo={{
                vatInc: priceProperties.vatInc,
                culture: priceProperties.locale,
              }}
              placeHolderText={"..."}
              tenant={tenant}
            ></VatMessage>
          </Typography>
        ) : (
          t("price-platform.no-setup-fee")
        )}
      </Box>
    </Typography>
  );
};
